html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}
#jumbo {
  padding: 1rem 1rem;
}

#camera--output {
  /*position: fixed;*/
  height: 100%;
  width: 100%;
  margin: 50px 50px;
  object-fit: cover;
}

#camera--view {
  transform: scaleX(-1);
  filter: FlipH;
  height: 500px;
  max-width: 100%;
  border-radius: 10px;
}

#camera--trigger {
  width: 200px;
  background-color: #00ad7e;
  color: white;
  font-size: 16px;
  border-radius: 30px;
  border: none;
  padding: 15px 20px;
  text-align: center;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  position: fixed;
  bottom: 30px;
  left: calc(50% - 100px);
}
.hidden {
  display: none;
}
.taken {
  position: fixed;
  height: 100px !important;
  width: 100px !important;
  transition: all 0.5s ease-in;
  border: solid 3px white;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  top: 20px;
  right: 20px;
  z-index: 2;
}

.wrapper {
  display: grid;
  grid-template-columns: 40% 40% 20%;
}

.wrapperHdr {
  display: grid;
  grid-template-columns: 40% 40% 20%;
}

.blinking {
  animation: blinkingText 1.2s infinite;
  width: 200px;
  background-color: #009fdb;
  color: white;
  font-size: 16px;
  border-radius: 30px;
  border: none;
  padding: 15px 20px;
  text-align: center;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  position: fixed;
  bottom: 30px;
  left: calc(50% - 100px);
}

.blinkingMsg {
  animation: blinkingText 1.2s infinite;
}
@keyframes blinkingText {
  0% {
    color: #fff;
  }
  49% {
    color: #fff;
  }
  60% {
    color: transparent;
  }
  99% {
    color: transparent;
  }
  100% {
    color: #fff;
  }
}

.service-profile-container {
  height: 20vh;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.service-profile-option {
  text-align: start;
}

.service-profile-checkbox {
  margin-right: 12px;
}